import React, { useEffect } from "react";

const Auth = () => {
  useEffect(() => {
    console.log("Auth");
  }, []);
  return (
    <div>Authenticating..</div>
  );
}

export default Auth;