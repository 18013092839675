import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "antd";
import {
  LockOutlined
} from '@ant-design/icons';

function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch(e => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button
          type="primary"
          icon={<LockOutlined />}
          onClick={() => handleLogin(instance)}
        >
          Sign in with Microsoft
        </Button>
    );
}

export default SignInButton;
