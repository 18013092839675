import React from "react";
import { Layout } from 'antd';
import { useLocation } from "react-router-dom";
const { Footer } = Layout;


const AppFooter = () => {
  const location = useLocation();

  if (
    location.pathname.includes("/login")
  ) {
    return "";
  }

  return (
    <Footer
      style={{
        textAlign: 'center',
        color: "#b0b0b6",
      }}
    >
      © 2022 George Clinical
    </Footer>
  );
};

export default AppFooter;