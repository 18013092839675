import React, { useEffect, useCallback, useState } from "react";
import { Row, Layout, List, Input, Avatar, Button, Card, Spin } from "antd";
import {
  BellOutlined,
} from '@ant-design/icons';
import { api } from "../../../lib";
import "./style.scss";
import Ceo from "../../../assets/ceo.jpeg"

const CeoMessage = () => {
  const [loading, setLoading] = useState(true);
  const [dataset, setDataset] = useState(null);

  const fetchData = useCallback(
    () => {
        setLoading(true);
      // eslint-disable-next-line no-useless-escape
      let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        api
          .get("https://six30labs.sharepoint.com/sites/Gctest/_api/web/lists/getByTitle('CEOMessages')/items?$orderBy=Created desc&$top=1", 
            { headers: { Authorization: `Bearer ${cookieValue}` } }
          )
          .then((res) => {
            console.log("ceoList", res);
            setDataset(res.data.value[0] || null);
            // setTotalCount(res.data.total);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
    },
    []
  );

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Card>
      <h3>From CEO's Desk</h3>
      <div>
        <img src={Ceo} style={{ height: 96, float: "right" }} />
        {dataset?.message}
      </div>
    </Card>
  );
};

export default CeoMessage;