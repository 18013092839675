import React, { useEffect, useCallback, useState } from "react";
import { Row, Col, Avatar, Button, Card, Spin } from "antd";
import {
  LinkOutlined,
  TwitterOutlined
} from '@ant-design/icons';
import { api } from "../../../lib";
import GClinical from "../../../assets/social/gClinical.jpeg"
import GInstitute from "../../../assets/social/gInstitute.jpeg"
import Blog1 from "../../../assets/home/blog1.jpg"
import Blog2 from "../../../assets/home/blog2.jpg"
import Blog3 from "../../../assets/home/blog3.jpg"
 
import "./style.scss";

const dataset = [
  {
    id: 1,
    type: "blog",
    title: "Mastercard: how to anonymize blockchain transactions.",
    body: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
    handle: "",
    url: "#",
    date: "",
    image: Blog1,
  },
  {
    id: 2,
    type: "twitter",
    title: "George Clinical",
    body: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. \nElit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
    handle: "@gClinical",
    url: "https://twitter.com/george_clinical",
    date: "",
    image: GClinical,
  },
  {
    id: 4,
    type: "blog",
    title: "Microsoft: the next hololens?",
    body: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.",
    handle: "",
    url: "#",
    date: "",
    image: Blog2,
  },
  {
    id: 3,
    type: "twitter",
    title: "George Institute",
    body: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.\nSunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
    handle: "@gInstitute",
    url: "https://twitter.com/georgeinstitute",
    date: "",
    image: GInstitute,
  },
  {
    id: 6,
    type: "blog",
    title: "Gatorade: sweat-reading wearable tracks hydration.",
    body: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
    handle: "",
    url: "#",
    date: "",
    image: Blog3,
  },
  {
    id: 5,
    type: "twitter",
    title: "George Clinical",
    body: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et.\nSunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
    handle: "@gClinical",
    url: "https://twitter.com/george_clinical",
    date: "",
    image: GClinical,
  },
  // {
  //   id: 7,
  //   type: "blog",
  //   title: "Apple: a screen in your sleeve?",
  //   body: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.",
  //   handle: "",
  //   url: "#",
  //   date: "",
  // },
  // {
  //   id: 8,
  //   type: "twitter",
  //   title: "",
  //   body: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. \nElit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
  //   handle: "@gClinical",
  //   url: "#",
  //   date: "",
  // },
];

const renderTwitter = (d) => {
  return (
    <div className="twitter-wrap">
      <Row>
        <Col>
          <Avatar src={d.image} size={48} />
        </Col>
        <Col flex="auto">
          <div>
            <h3>{d.title}</h3>
            <p>{d.handle}</p>
          </div>
        </Col>
      </Row>
      <p className="social-body">{d.body}</p>
      <div className="twitter-icon">
        <a href={d.url}>
          <TwitterOutlined style={{ color: "#d2d2d9", fontSize: 20 }} />
        </a>
      </div>
    </div>
  );
};

const renderBlog = (d) => {
  return (
    <div className="blog-wrap">
      <div
        className="blog-title"
        style={{
          backgroundImage: `url(${d.image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      />
      <h3>{d.title}</h3>
      <p className="social-body">{d.body}</p>
      <p><LinkOutlined style={{ color: "#c2c2c9", marginRight: 6 }} /><a href="#">Read more..</a></p>
    </div>
  );
};

const SocialWall = () => {
  const [loading, setLoading] = useState(false);
  // const [dataset, setDataset] = useState([]);
  const fetchToken = useCallback(
    () => {
        setLoading(true);
      // eslint-disable-next-line no-useless-escape
      let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      const today = new Date();
        api
      // "https://gclinical.sharepoint.com/sites/GCwiztest1/_api/web/lists/getbytitle('Holidays')/items?$select=Title,Location,EventDate,EndDate&$filter=EventDate ge datetime'" + today + "'&$OrderBy=EventDate asc&$top=40";
          .post("https://api.twitter.com/oauth2/token?grant_type=client_credentials",
          {"33gT2fPSAfdtdvpX6Vo6aNcYZ": "M5U2GWMUv9D6hfHHdR1C5CqiM4exul6Pi2i2iHvmBJnpePqWKY"}
          )
          .then((res) => {
            console.log("tList", res);
            // setDataset(res.data.value);
            // setTotalCount(res.data.total);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
    },
    []
  );

  useEffect(() => {
    // fetchToken();
  }, []);

  return (
    <Card id="widget-socialwall">
      <Row justify="space-between">
        <h2>Social Wall</h2>
        <Button size="small">View All</Button>
      </Row>
      {loading ? (
        <Spin />
      ) : ( dataset.length > 0
        ? (
          <div>
            <Row gutter={[12, 12]}>
              {dataset.map((d, dI) => (
                <Col key={dI} xs={24} sm={24} md={12} lg={12} xl={8}>
                    <Card>
                      {d.type === "twitter" ? renderTwitter(d) : renderBlog(d)}
                    </Card>
                </Col>
              ))}
            </Row>
          </div>
          )
        : <div>No posts found</div>)
      }
    </Card>
  );
};

export default SocialWall;