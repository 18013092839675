export const msalConfig = {
  auth: {
    clientId: "1e2ca57c-4fcb-47c8-8e8a-567151039b65",
    authority: "https://login.microsoftonline.com/4aa11847-3bec-4b1d-a77c-dfb439df25e4",
    redirectUri: "https://gc3.six30labs.com",
    postLogoutRedirectUri: "https://gc3.six30labs.com/login",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["https://six30labs.sharepoint.com/AllSites.FullControl"]
//  scopes: ["User.Read", "https://six30labs.sharepoint.com/.default"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};