import React, { useEffect, useCallback, useState } from "react";
import { Row, Col, Space, Input, List, Avatar, Button, Card, Spin, Typography } from "antd";
import {
  BellOutlined,
} from '@ant-design/icons';
import { api } from "../../../lib";
import moment from "moment";
import "./style.scss";
const { Paragraph } = Typography;
const Events = () => {
  const [loading, setLoading] = useState(true);
  const [dataset, setDataset] = useState([]);

  const fetchData = useCallback(
    () => {
        setLoading(true);
      // eslint-disable-next-line no-useless-escape
      let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      const today = new Date();
        api
      // "https://gclinical.sharepoint.com/sites/GCwiztest1/_api/web/lists/getbytitle('Holidays')/items?$select=Title,Location,EventDate,EndDate&$filter=EventDate ge datetime'" + today + "'&$OrderBy=EventDate asc&$top=40";
          .get("https://six30labs.sharepoint.com/sites/Gctest/_api/web/lists/getByTitle('Events')/items", 
            { headers: { Authorization: `Bearer ${cookieValue}` } }
          )
          .then((res) => {
            console.log("eList", res);
            setDataset(res.data.value);
            // setTotalCount(res.data.total);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
    },
    []
  );

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Card>
      <Row justify="space-between">
        <h3>Events</h3>
        <Button size="small">View All</Button>
      </Row>
      {loading ? (
        <div className="loading-wrap-sm">
          <Spin />
        </div>
      ) : ( dataset.length > 0
        ? (
          <List
            itemLayout="horizontal"
            dataSource={dataset}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={<BellOutlined />} />}
                  title={item.Title}
                  description={(
                    <div>
                      <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }} style={{ color: "rgb(68, 68, 68)" }}>
                        {item.description}
                      </Paragraph>
                      <div>{moment(item.startdate)?.format("DD MMM YYYY hh:mm a")} - {moment(item.enddate)?.format("DD MMM YYYY hh:mm a")}</div>
                    </div>
                  )}
                />
              </List.Item>
            )}
          />
          )
        : <div>No data</div>)
      }
    </Card>
  );
};

export default Events;