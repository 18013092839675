import React, { useState } from "react";
import {
  UserOutlined,
  SettingOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Row, Input, Avatar, Dropdown, Space } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import People3 from "../../assets/people/3.jpg"
import { useMsal } from "@azure/msal-react";
const { Header } = Layout;
const { Search } = Input;

function getItem(label, key, children) {
  return {
    key,
    children,
    label,
  };
}
const paths = {
  "menu-home": "/",
  "menu-about": "/about",
  "menu-people": "/people",
  "admin-gl": "/services/admin/global",
};

const items = [
  getItem('Home', 'menu-home'),
  getItem('About', 'menu-about'),
  getItem('Services', 'services', [
    getItem('Admin', 'admin', null, [
      getItem('Global', 'admin-gl', null),
      getItem('Australia', 'admin-au', null),
      getItem('China', 'admin-cn', null),
      getItem('India', 'admin-in', null),
      getItem('East Asia', 'admin-ea', null),
    ]),
    getItem('IT', 'it', null),
    getItem('Finance', '5', null, [
      getItem('Global', 'finance-gl', null),
      getItem('Australia', 'finance-au', null),
      getItem('China', 'finance-cn', null),
      getItem('India', 'finance-in', null),
      getItem('East Asia', 'finance-ea', null),
      getItem('UK', 'finance-uk', null),
      getItem('USA', 'finance-us', null),
    ], null),
    getItem('HR', 'hr', null, [
      getItem('Global', 'hr-gl', null),
      getItem('Australia', 'hr-au', null),
      getItem('China', 'hr-cn', null),
      getItem('India', 'hr-in', null),
      getItem('East Asia', 'hr-ea', null),
      getItem('UK', 'hr-uk', null),
      getItem('USA', 'hr-us', null),
    ]),
    getItem('Legal', 'legal', null),
    getItem('Business Solutions', 'bs', null),
    getItem('Marketing Solutions', 'ms', null),
  ]),
  getItem('SOP', 'sop'),
  getItem('People', 'menu-people'),
  getItem('Careers', 'careers'),
];

const AppHeader = () => {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  const onSearch = (value) => console.log(value);

  if (
    location.pathname.includes("/login")
  ) {
    return "";
  }

  const userMenu = (
    <Menu
      onClick={(e) => {
        if(e.key === "user-signout") {
          document.cookie = "AUTH_TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          instance.logoutRedirect().catch(e => {
            console.error(e);
          });
        }
      }}
      items={[
        {
          key: 'user-profile',
          label: "Profile",
          icon: <UserOutlined />,
        },
        {
          key: 'user-settings',
          label: "Settings",
          icon: <SettingOutlined />,
        },
        {
          key: 'user-signout',
          danger: true,
          label: 'Sign out',
          icon: <PoweroffOutlined />,
        },
      ]}
    />
  );

  return (
    <Header>
      <div className="logo">
        <img src={logo} />
      </div>
      <Row align='middle' style={{ flex: 1, justifyContent: "flex-end" }}>
        <Menu
          theme="light"
          defaultSelectedKeys={['menu-home']} // TODO: Get from url location
          mode="horizontal"
          items={items}
          onClick={(e) => {
            if(paths[e.key]) {
              navigate(paths[e.key]);
            }
          }}
        />
          <Search placeholder="Search.." allowClear onSearch={onSearch} style={{ width: 200 }} />
          <Dropdown overlay={userMenu}>
            <a onClick={e => e.preventDefault()}>
              <Avatar size={40} src={People3} style={{ marginLeft: 12 }} />
            </a>
          </Dropdown>
      </Row>
    </Header>
  );
};

export default AppHeader;