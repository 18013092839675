import React, { useState, useCallback, useEffect } from "react";
import {
  ToolOutlined,
  FileTextOutlined,
  TeamOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  SolutionOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../../lib";
import logo from "../../assets/logo-white.png";
import { useMsal } from "@azure/msal-react";
const { Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    children,
    label,
    icon,
  };
}
const paths = {
  "menu-home": "/",
  "menu-about": "/about",
  "menu-people": "/people",
  "admin-gl": "/services/admin/global",
};

const items = [
  getItem('Home', 'menu-home', <HomeOutlined style={{ fontSize: 20 }} />),
  getItem('About', 'menu-about', <InfoCircleOutlined style={{ fontSize: 20 }} />),
  getItem('Services', 'services', <ToolOutlined style={{ fontSize: 20 }} />, [
    getItem('Admin', 'admin', null, [
      getItem('Global', 'admin-gl', null),
      getItem('Australia', 'admin-au', null),
      getItem('China', 'admin-cn', null),
      getItem('India', 'admin-in', null),
      getItem('East Asia', 'admin-ea', null),
    ]),
    getItem('IT', 'it', null),
    getItem('Finance', '5', null, [
      getItem('Global', 'finance-gl', null),
      getItem('Australia', 'finance-au', null),
      getItem('China', 'finance-cn', null),
      getItem('India', 'finance-in', null),
      getItem('East Asia', 'finance-ea', null),
      getItem('UK', 'finance-uk', null),
      getItem('USA', 'finance-us', null),
    ], null),
    getItem('HR', 'hr', null, [
      getItem('Global', 'hr-gl', null),
      getItem('Australia', 'hr-au', null),
      getItem('China', 'hr-cn', null),
      getItem('India', 'hr-in', null),
      getItem('East Asia', 'hr-ea', null),
      getItem('UK', 'hr-uk', null),
      getItem('USA', 'hr-us', null),
    ]),
    getItem('Legal', 'legal', null),
    getItem('Business Solutions', 'bs', null),
    getItem('Marketing Solutions', 'ms', null),
  ]),
  getItem('SOP', 'sop', <FileTextOutlined style={{ fontSize: 20 }} />),
  getItem('People', 'menu-people', <TeamOutlined style={{ fontSize: 20 }} />),
  getItem('Careers', 'careers', <SolutionOutlined style={{ fontSize: 20 }} />),
  getItem('Sign out', 'menu-logout', <LogoutOutlined style={{ fontSize: 20 }} />),
];

const AppSidebar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();

  const fetchData = useCallback(
    () => {
        // setLoading(true);
      // eslint-disable-next-line no-useless-escape
      let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        api
          .get("https://six30labs.sharepoint.com/sites/Gctest/_api/web/lists/getByTitle('Menu')/items", 
            { headers: { Authorization: `Bearer ${cookieValue}` } }
          )
          .then((res) => {
            console.log("menu", res);
            // setDataset(res.data.data);
            // setTotalCount(res.data.total);
            // setLoading(false);
          })
          .catch((err) => {
            // notify({
            //   type: "error",
            //   message: "Err",
            // });
            // setLoading(false);
            console.error(err);
          });
    },
    []
  );

  useEffect(() => {
    // fetchData();
  }, []);

  if (
    location.pathname.includes("/login")
  ) {
    return "";
  }
  return (
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <div className="logo" style={collapsed ? { justifyContent: "center"} : { justifyContent: "flex-start", paddingLeft: 24, paddingRight: 24}}>
        <img src={logo} />
        {!collapsed &&
        <div>
          <span>George Clinical</span>
          {/* <span>Clinical</span> */}
        </div>
        }
      </div>
      <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items}
        onClick={(e) => {
          if (e.key === "menu-logout") {
            instance.logoutPopup().then(() => {
              document.cookie = "AUTH_TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              navigate('login');
            }).catch(e => {
              console.error(e);
            });
          }
          if(paths[e.key]) {
            navigate(paths[e.key]);
          }
        }} />
    </Sider>
  );
};

export default AppSidebar;