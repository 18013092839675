import React, { useEffect, useCallback, useState } from "react";
import { Row, Layout, List, Input, Avatar, Button, Card, Spin } from "antd";
import {
  ReadOutlined,
} from '@ant-design/icons';
import { api } from "../../../lib";
import "./style.scss";

const Blog = () => {
  const [loading, setLoading] = useState(true);
  const [dataset, setDataset] = useState([]);

  const fetchData = useCallback(
    () => {
        setLoading(true);
      // eslint-disable-next-line no-useless-escape
      let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        api
          .get("https://georgeclinical.com/wp-json/wp/v2/posts?per_page=5", 
            { headers: { Authorization: `Bearer ${cookieValue}` } }
          )
          .then((res) => {
            console.log("listB", res);
            setDataset(res.data);
            // setTotalCount(res.data.total);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
    },
    []
  );

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Card>
      <Row justify="space-between">
        <h3>Latest News</h3>
        <Button size="small">View All</Button>
      </Row>
      {loading ? (
        <div className="loading-wrap-sm">
          <Spin />
        </div>
      ) : ( dataset.length > 0
        ? (
          <List
            itemLayout="horizontal"
            dataSource={dataset}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={<ReadOutlined />} />}
                  title={<a href={item.link} target="blank">{item.title?.rendered}</a>}
                  // description={item.excerpt?.rendered.substring(0, 64)}
                />
              </List.Item>
            )}
          />)
        : <div>No data</div>)
      }
    </Card>
  );
};

export default Blog;