import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, useNavigate } from "react-router-dom";

import { Layout, Spin } from 'antd';
import { Home, About, People, AdminGlobal, Login } from './pages';
import { Auth } from './pages/auth';
import { 
  // AuthenticatedTemplate,
  // UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { api } from "./lib"
import { AppSidebar, AppFooter } from './components';

// function FetchToken() {
//   const { instance, accounts, inProgress } = useMsal();

//   useEffect(() => {
//     const request = {
//       ...loginRequest,
//       account: accounts[0]
//     };

//     // Silently acquires an access token which is then attached to a request for Microsoft Graph data
//     instance.acquireTokenSilent(request).then((response) => {
//       console.log(response.accessToken);
//         console.log(response.accessToken);
//         document.cookie = `AUTH_TOKEN=${response.accessToken}; expires=604800; path=/`
//         api.setToken(response.accessToken);
//     }).catch((e) => {
//         instance.acquireTokenPopup(request).then((response) => {
//           document.cookie = `AUTH_TOKEN=${response.accessToken}; expires=604800; path=/`
//           console.log(response.accessToken);
//           api.setToken(response.accessToken);
//         });
//     });
//   }, []);

// }



function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();


  const fetchProfile = useCallback((cookieValue) => {
    // console.log("fn: fetchProfile");
    api
    .get("https://six30labs.sharepoint.com/sites/Gctest/_api/web/currentUser", 
      { headers: { Authorization: `Bearer ${cookieValue}` } }
    )
    .then((res) => {
      console.log("Profile", res);
      // TODO: Save User details
      navigate("/");
      setLoading(false);
    })
    .catch((err) => {
      console.error("ERR", err);
      document.cookie = "AUTH_TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      navigate("login");
      setLoading(false);
    });
  }, [setLoading]);

  const init = useCallback(async () => {
    await instance.handleRedirectPromise();
    // console.log("fn: init");
    const fetchToken = () => {
      // console.log("fn: fetchToken");
      // const { instance, accounts, inProgress } = useMsal();
      const request = {
        ...loginRequest,
        account: accounts[0]
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request)
        .then((response) => {
          // console.log(response.accessToken);
            document.cookie = `AUTH_TOKEN=${response.accessToken}; expires=604800; path=/`
            api.setToken(response.accessToken);
            fetchProfile(response.accessToken);
        }).catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            document.cookie = `AUTH_TOKEN=${response.accessToken}; expires=604800; path=/`
            console.log("catch", response.accessToken);
            api.setToken(response.accessToken);
            fetchProfile(response.accessToken);
          })
          .catch(() => {
            document.cookie = "AUTH_TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            navigate("login");
            setLoading(false);
          });
        });
    }

    // console.log("isAuth", isAuthenticated);
    if (isAuthenticated) {
      // eslint-disable-next-line no-useless-escape
      let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      // console.log(cookieValue);
      if(cookieValue) {
        fetchProfile(cookieValue);
      } else {
        fetchToken();
      }
    } else {
      // instance.loginRedirect(loginRequest).catch(e => {
      //   console.error(e);
      // });
      document.cookie = "AUTH_TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      navigate("login");
      setLoading(false);
    }
  }, [accounts, fetchProfile, instance, isAuthenticated]);

  useEffect(() => {
    init();
  }, [init]);

  
  if (loading) {
    return (
      <div className='loading-wrap'>
        <Spin style={{ marginRight: 16 }} />
        <h3 style={{ marginBottom: 0, color: "#0156" }}>Authenticating</h3>
      </div>
    );
  }

  return (
    <div className="App">
      {/* <div style={{ border: "solid red 2px" }}>
        <AuthenticatedTemplate>
            <Space direction="horizontal">
              <p style={{ marginBottom: 0, marginRight: 24 }}>You are signed in!</p>
              <SignOutButton />
            </Space>
            <FetchToken />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Space direction="horizontal">
              <p style={{ marginBottom: 0, marginRight: 24 }}>You are not signed in! Please sign in.</p>
              <SignInButton />
            </Space>
        </UnauthenticatedTemplate>
      </div> */}
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <AppSidebar />
        <Layout className="site-layout">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="about" element={<About />} />
            <Route path="people" element={<People />} />
            <Route path="services/admin/global" element={<AdminGlobal />} />
            <Route path="auth" element={<Auth />} />
          </Routes>
        </Layout>
      </Layout>
      <AppFooter />
    </div>
  );
}

export default App;
