import React, { useEffect } from "react";
import SignInButton from "./SignInButton";
import Logo from "../../assets/logo-auth.png"
import "./auth.scss";

const Login = () => {
  return (
    <div id="page-login">
      <img src={Logo} />
      <SignInButton />
    </div>
  );
}

export default Login;