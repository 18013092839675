import React, { useEffect, useCallback, useState } from "react";
import { Carousel } from "antd";
import { api } from "../../../lib";
import "./style.scss";


const Slideshow = () => {
  const [loading, setLoading] = useState(true);
  const [dataset, setDataset] = useState([]);

  const fetchData = useCallback(
    () => {
        setLoading(true);
      // eslint-disable-next-line no-useless-escape
      let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      // "https://gclinical.sharepoint.com/sites/GCwiztest1/_api/web/lists/getbytitle('Holidays')/items?$select=Title,Location,EventDate,EndDate&$filter=EventDate ge datetime'" + today + "'&$OrderBy=EventDate asc&$top=40";
      api
        // .get("https://six30labs.sharepoint.com/sites/Gctest/_api/web/lists/getByTitle('Slideshow')/items?$filter=''", 
        // .get("https://six30labs.sharepoint.com/sites/Gctest/_api/web/lists/getbytitle('Slideshow')/items?$select=Title,description,image,link,url,location&$filter=location eq 'home'&$orderBy=order0 asc", 
        .get("https://six30labs.sharepoint.com/sites/Gctest/_api/web/lists/getbytitle('Slideshow')/items?$filter=location eq 'home'&$orderBy=order0 asc", 
          { headers: { Authorization: `Bearer ${cookieValue}` } }
        )
        .then((res) => {
          console.log("sList", res);
          const slides = res.data.value.map((v) => {
            const parsed = JSON.parse(v.image);
            // const img = parsed.serverUrl + parsed.serverRelativeUrl;
            // console.log(parsed);
            return { ...v, img: parsed?.serverUrl + parsed?.serverRelativeUrl };
          });
          setDataset(slides);
          // setTotalCount(res.data.total);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    },
    []
  );
  const fetchData2 = useCallback(
    () => {
      // eslint-disable-next-line no-useless-escape
      let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        api
          .get("https://six30labs.sharepoint.com/sites/Gctest/_api/web/SiteUsers", 
            { headers: { Authorization: `Bearer ${cookieValue}` } }
          )
          .then((res) => {
            console.log("allUsers", res);
            // setTotalCount(res.data.total);
          })
          .catch((err) => {
            console.error(err);
          });
    },
    []
  );

  useEffect(() => {
    fetchData();
    fetchData2();
  }, []);

  return (
    
    <Carousel autoplay autoplaySpeed={3000} dotPosition="bottom">
      { dataset.map((s, sI) => (
        <div key={sI} className="slide-wrap">
          <div
            style={{
              height: 480,
              backgroundImage: `url(${s.img})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}
          >
            <div className="slide-caption">
              <h3>{s.Title}</h3>
              <p>{s.description}</p>
              <a href={s.url || "#"} target="_blank">{s.link || "Read more"}</a>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default Slideshow;