import React, { useEffect, useState, useCallback } from "react";
import { Card, Row, Col, List, Button, Space, Input, Tag, Avatar, Menu, Dropdown } from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  SearchOutlined,
  UserOutlined,
  LinkOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import "./style.scss";
import { api } from "../../lib";

import Contact1 from "../../assets/contacts/1.png"
import Contact2 from "../../assets/contacts/2.png"
import Contact3 from "../../assets/contacts/3.png"
import Contact4 from "../../assets/contacts/4.png"
import PeopleMain from "../../assets/people/PeopleMain.jpg"
import PeopleSub1 from "../../assets/people/PeopleSub1.jpg"
import PeopleSub2 from "../../assets/people/PeopleSub2.jpg"

const Overview = () => {
  const [loading, setLoading] = useState(true);
  const [dataset, setDataset] = useState([]);

  const fetchData = useCallback(
    () => {
        setLoading(true);
      // eslint-disable-next-line no-useless-escape
      let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      // "https://gclinical.sharepoint.com/sites/GCwiztest1/_api/web/lists/getbytitle('Holidays')/items?$select=Title,Location,EventDate,EndDate&$filter=EventDate ge datetime'" + today + "'&$OrderBy=EventDate asc&$top=40";
      api
        // .get("https://six30labs.sharepoint.com/sites/Gctest/_api/web/lists/getByTitle('Slideshow')/items?$filter=''", 
        .get("https://six30labs.sharepoint.com/sites/Gctest/_api/web/lists/getbytitle('Slideshow')/items?$select=Title,description,image,link,url,location&$filter=location eq 'people'&$orderBy=order0 asc", 
          { headers: { Authorization: `Bearer ${cookieValue}` } }
        )
        .then((res) => {
          console.log("listC", res);
          const slides = res.data.value.map((v) => {
            const parsed = JSON.parse(v.image);
            return { ...v, img: parsed?.serverUrl + parsed?.serverRelativeUrl };
          });
          setDataset(slides);
          // setTotalCount(res.data.total);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    },
    []
  );

  useEffect(() => {
    fetchData();
  }, []);

  console.log('ds', dataset);

  const contacts = [
    {
      name: "Arlene McCoy",
      designation: "Marketing Coordinator",
      image: Contact1,
    },
    {
      name: "Brooklyn Simmons",
      designation: "Nursing Assistant",
      image: Contact2,
    },
    {
      name: "Darrell Steward",
      designation: "Medical Assistant",
      image: Contact3,
    },
    {
      name: "Marvin McKinney",
      designation: "President of Sales",
      image: Contact4,
    },
  ];
  const employees = [
    {
      name: "Arlene McCoy",
      department: "Operations",
      designation: "Marketing Coordinator",
      location: "Kent, Utah",
      image: Contact1,
    },
    {
      name: "Brooklyn Simmons",
      department: "Executive",
      designation: "Nursing Assistant",
      location: "Pasadena, Oklahoma",
      image: Contact2,
    },
    {
      name: "Darrell Steward",
      department: "IT",
      designation: "Medical Assistant",
      location: "Stockton, NH",
      image: Contact3,
    },
    {
      name: "Marvin McKinney",
      department: "Payroll",
      designation: "President of Sales",
      location: "Lansing, Illinois",
      image: Contact4,
    },
  ];
  return (
    <Row gutter={16}>
      <Col span={16}>
        <Row>
          <Col span={16} style={{ paddingRight: 8}}>
            <div
              className="slide-main"
              style={{
                // height: 480,
                backgroundImage: `url(${dataset[0]?.img})`,
              }}>
              <div className="slide-caption">
                <h3>{dataset[0]?.Title}</h3>
                <p>{dataset[0]?.description}</p>
                <a href="#" target="_blank">Read more</a>
              </div>
            </div>
          </Col>
          <Col span={8} style={{ padding: 0 }}>
            <div
              className="slide-item"
              style={{
                marginBottom: 8,
                backgroundImage: `url(${dataset[1]?.img})`,
              }}>
              <div className="slide-caption">
                <h3>{dataset[1]?.Title}</h3>
                <p>{dataset[1]?.description}</p>
              </div>
            </div>
            <div
              className="slide-item"
              style={{
                backgroundImage: `url(${dataset[2]?.img})`,
              }}>
              <div className="slide-caption">
                <h3>{dataset[2]?.Title}</h3>
                <p>{dataset[2]?.description}</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={8} style={{ marginTop: 16 }}>
          <Col span={6}>
            <Button icon={<LinkOutlined />} style={{ width: "100%", height: 80 }}>Leaves</Button>
          </Col>
          <Col span={6}>
            <Button icon={<LinkOutlined />} style={{ width: "100%", height: 80 }}>Time Sheets</Button>
          </Col>
          <Col span={6}>
            <Button icon={<LinkOutlined />} style={{ width: "100%", height: 80 }}>Feedback</Button>
          </Col>
          <Col span={6}>
            <Button icon={<LinkOutlined />} style={{ width: "100%", height: 80 }}>Policies</Button>
          </Col>
        </Row>
        <Card style={{ marginTop: 16 }}>
          <h3>In the Spotlight</h3>
          <Row>
            { employees.map((c, cI) => (
              <Col span={6} key={cI} className="spotlight-item">
                <Avatar src={c.image} size={128} />
                <div>
                  <h4>{c.name}</h4>
                  <p>{c.department}</p>
                  <p>{c.location}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Row justify="space-between">
            <h3>Birthdays</h3>
            <Button size="small">View All</Button>
          </Row>
          <List
            itemLayout="horizontal"
            dataSource={contacts}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar size={72} src={item.image} />}
                  title={item.name}
                  description={item.designation}
                />
              </List.Item>
            )}
          />
        </Card>
        <Card>
          <Row justify="space-between">
            <h3>Work Anniversaries</h3>
            <Button size="small">View All</Button>
          </Row>
          <List
            itemLayout="horizontal"
            dataSource={contacts}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar size={72} src={item.image} />}
                  title={item.name}
                  description={item.designation}
                />
              </List.Item>
            )}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Overview;